"use client";
import { loadStripe } from "@stripe/stripe-js";
import { auth } from "../firebase";
import { Box, Stack, HStack, Heading, Text, VStack, useColorModeValue, List, ListItem, ListIcon, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

const pricingPlans = [
    {
        name: "Free",
        price: "0",
        currencySign: "$",
        subscriptionLength: "free",
        features: ["shortcuts"],
        button: "Just install",
    },
    {
        name: "1 Month",
        price: "0",
        currencySign: "$",
        subscriptionLength: "month",
        features: ["auto relist", "shortcuts", "auto sbc", "sniper", "filters", "auto relist", "auto bid"],
        button: "Create account",
        isDisabled: false,
    },
    // {
    //     name: "Full Season",
    //     price: "9.99",
    //     currencySign: "$",
    //     subscriptionLength: "season",
    //     features: ["auto sbc", "sniper", "filters", "auto relist", "auto bid"],
    //     button: "Get Membership",
    //     isDisabled: true, // This plan is disabled
    // },
];

function PriceWrapper(props) {
    const { children } = props;
    return (
        <Box mb={4} shadow="base" borderWidth="1px" alignSelf={{ base: "center", lg: "flex-start" }} borderColor={useColorModeValue("gray.200", "gray.500")} borderRadius={"xl"}>
            {children}
        </Box>
    );
}

export default function PricingArea() {
    const navigate = useNavigate();
    const bgColor = useColorModeValue("gray.50", "gray.700");

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const handlePurchase = async (type) => {
        if (!user && type != "free") {
            navigate("signup");
        } else {
            window.location.href = "https://chromewebstore.google.com/detail/ut-web-helper/afhliekobdpikegigkiihhoggiajijpf";
        }
        return;
        const stripe = await stripePromise;
        const token = localStorage.getItem("token"); // Retrieve token from local storage

        // Call backend to create a new checkout session
        const response = await fetch(process.env.REACT_APP_API_SERVER + "/api/payment/create-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Include token in headers
            },
            body: JSON.stringify({ userId: user.uid, type: type }), // Assuming you have the user's UID here
        });

        if (response.status === 409) {
            // Handle the case where the user already has a subscription
            alert("You've already paid for the subscription!");
            return;
        }

        const session = await response.json();

        // Redirect to Stripe's hosted checkout page
        const result = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });

        if (result.error) {
            // Handle error here
        }
    };

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Premium
                </Heading>
                <Text fontSize="lg" color={"gray.500"}></Text>
            </VStack>
            <Stack direction={{ base: "column", md: "row" }} textAlign="center" justify="center" spacing={{ base: 4, lg: 10 }} py={10}>
                {pricingPlans.map((plan, index) => (
                    <PriceWrapper key={index} style={{ opacity: plan.isDisabled ? 0.5 : 1 }}>
                        <Box py={4} px={12}>
                            <Text fontWeight="500" fontSize="2xl">
                                {plan.name}
                            </Text>
                            <HStack justifyContent="center">
                                <Text fontSize="3xl" fontWeight="600">
                                    {plan.currencySign}
                                </Text>
                                <Text fontSize="5xl" fontWeight="900">
                                    {plan.price}
                                </Text>
                                <Text fontSize="3xl" color="gray.500">
                                    /{plan.subscriptionLength}
                                </Text>
                            </HStack>
                        </Box>
                        <VStack bg={bgColor} py={4} borderBottomRadius={"xl"}>
                            <List spacing={3} textAlign="start" px={12}>
                                {plan.features.map((feature, featureIndex) => (
                                    <ListItem key={featureIndex}>
                                        <ListIcon as={FaCheckCircle} color="green.500" />
                                        {feature}
                                    </ListItem>
                                ))}
                            </List>
                            <Box w="80%" pt={7}>
                                <Button
                                    w="full"
                                    onClick={() => (plan.isDisabled ? null : handlePurchase(plan.subscriptionLength))}
                                    colorScheme="red"
                                    variant="outline"
                                    isDisabled={plan.isDisabled}
                                >
                                    {plan.button}
                                </Button>
                            </Box>
                        </VStack>
                    </PriceWrapper>
                ))}
            </Stack>
        </Box>
    );
}
