import React, { useState, useEffect } from "react";
import { Button, Flex, FormControl, FormLabel, HStack, Icon, Input, Link, Switch, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from "firebase/auth";
import { auth } from "../firebase"; // Adjust this path as necessary
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import whApiService from "../services/apiWhService";

function SignUp() {
    const titleColor = useColorModeValue("teal.300", "teal.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error] = useState("");
    const toast = useToast();

    const hideSocial = true;

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Dynamically load the reCAPTCHA script
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=6LfS4GQpAAAAAK1A-0A1JREElTvh7r5lSa0RzCKM`;
        script.addEventListener("load", () => {
            console.log("reCAPTCHA script loaded.");
        });
        document.body.appendChild(script);
    }, []);

    if (currentUser) {
        navigate("/");
        return null;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        window.grecaptcha.ready(async () => {
            window.grecaptcha.execute("6LfS4GQpAAAAAK1A-0A1JREElTvh7r5lSa0RzCKM", { action: "submit" }).then(async (token) => {
                // Here you can optionally verify the token on your server before proceeding with user registration
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                    // Add reCAPTCHA token to your backend call if needed
                    // Example: await signUpUser(email, password, token);

                    sendEmailVerification(userCredential.user)
                        .then(async () => {
                            const userData = {
                                userId: userCredential.user.uid,
                                email: userCredential.user.email
                                // Add other user data you want to save
                            };
                            await whApiService.saveUserData(userData);
                            navigate("/email-verification");
                        })
                        .catch((error) => {
                            // Handle errors here
                        });
                    setEmail("");
                    setPassword("");
                } catch (error) {
                    let errorMessage = "An error occurred during sign up.";
                    if (error.code === "auth/email-already-in-use") {
                        errorMessage = "This email is already in use. Please try another one.";
                    } else if (error.code === "auth/invalid-email") {
                        errorMessage = "Invalid email. Please enter a valid email address.";
                    }
                    toast({
                        title: "Error",
                        description: errorMessage,
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                        position: "top",
                    });
                }
            });
        });
    };

    return (
        <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
            <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
                <Flex
                    as="form"
                    onSubmit={handleSubmit}
                    direction="column"
                    w="445px"
                    background="transparent"
                    borderRadius="15px"
                    p="40px"
                    mx={{ base: "100px" }}
                    bg={bgColor}
                    boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
                >
                    <Text fontSize="xl" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
                        Register With
                    </Text>
                    <HStack hidden={hideSocial ? "hidden" : ""} spacing="15px" justify="center" mb="22px">
                        <Flex
                            justify="center"
                            align="center"
                            w="75px"
                            h="75px"
                            borderRadius="15px"
                            border="1px solid lightgray"
                            cursor="pointer"
                            transition="all .25s ease"
                            _hover={{ filter: "brightness(120%)", bg: bgIcons }}
                        >
                            <Link href="#">
                                <Icon as={FaFacebook} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
                            </Link>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            w="75px"
                            h="75px"
                            borderRadius="15px"
                            border="1px solid lightgray"
                            cursor="pointer"
                            transition="all .25s ease"
                            _hover={{ filter: "brightness(120%)", bg: bgIcons }}
                        >
                            <Link href="#">
                                <Icon as={FaApple} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
                            </Link>
                        </Flex>
                        <Flex
                            justify="center"
                            align="center"
                            w="75px"
                            h="75px"
                            borderRadius="15px"
                            border="1px solid lightgray"
                            cursor="pointer"
                            transition="all .25s ease"
                            _hover={{ filter: "brightness(120%)", bg: bgIcons }}
                        >
                            <Link href="#">
                                <Icon as={FaGoogle} w="30px" h="30px" _hover={{ filter: "brightness(120%)" }} />
                            </Link>
                        </Flex>
                    </HStack>

                    <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Email
                        </FormLabel>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fontSize="sm"
                            ms="4px"
                            borderRadius="15px"
                            type="email"
                            placeholder="Your email address"
                            mb="24px"
                            size="lg"
                        />
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Password
                        </FormLabel>
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fontSize="sm"
                            ms="4px"
                            borderRadius="15px"
                            type="password"
                            placeholder="Your password"
                            mb="24px"
                            size="lg"
                        />
                        <Button
                            type="submit"
                            bg="teal.300"
                            fontSize="10px"
                            color="white"
                            fontWeight="bold"
                            w="100%"
                            h="45"
                            mb="24px"
                            _hover={{
                                bg: "teal.200",
                            }}
                            _active={{
                                bg: "teal.400",
                            }}
                        >
                            SIGN UP
                        </Button>
                        {error && <Text color="red.500">{error}</Text>} {/* Display error message */}
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
                        <Text color={textColor} fontWeight="medium">
                            Already have an account?
                            <Link
                                color={titleColor}
                                as="span"
                                ms="5px"
                                href="#"
                                fontWeight="bold"
                                onClick={() => {
                                    navigate("/login");
                                }}
                            >
                                Sign In
                            </Link>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignUp;
