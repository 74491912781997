import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useToast, VStack, Heading, FormControl, FormLabel, Input, Button, Text, Alert, InputGroup, Container, AlertIcon } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const toast = useToast();

    const handleLogin = async (event) => {
        event.preventDefault(); 
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            if (!userCredential.user.emailVerified) {
                // Show a message that the email is not verified
                navigate("/email-verification");
                return null;
            } else {
                const token = await userCredential.user.getIdToken();
                localStorage.setItem("token", token); // Store token for future requests
               
                setError("");
                toast({
                    title: "Logged in successfully.",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                navigate("/");
            }
        } catch (e) {
            setError(e.message);
            toast({
                title: "Error",
                description: e.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Container maxW="container.md" mt={10}>
            <VStack as="form" onSubmit={handleLogin} spacing={4} align="stretch">
                <Text fontSize="2xl" fontWeight="bold">
                    Login
                </Text>
                {error && (
                    <Alert status="error">
                        <AlertIcon />
                        {error}
                    </Alert>
                )}
                <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <Input type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </FormControl>
                <Button colorScheme="teal" type="submit">
                    Login
                </Button>
                <Text>
                    Don't have an account?{" "}
                    <Text as="span" color="teal.500" fontWeight="bold" cursor="pointer" onClick={() => navigate("/register")}>
                        Sign Up
                    </Text>
                </Text>
            </VStack>
        </Container>
    );
}

export default Login;
