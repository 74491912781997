import React from 'react';
import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useAuth } from './AuthContext'; // Import useAuth

const UserProfile = () => {
  const { currentUser } = useAuth();

  // Determine what to display next to the Avatar
  const userNameOrEmail = currentUser?.displayName || currentUser?.email || "User";

  return (
    <Flex align="center"> {/* Flex container to align items horizontally */}
      <Avatar size="md" name={userNameOrEmail} mr={2} /> {/* Avatar with right margin */}
      <Text>{userNameOrEmail}</Text> {/* Displaying the user's name or email */}
    </Flex>
  );
}

export default UserProfile;
