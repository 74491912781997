import React, { useEffect, useState } from "react";
import { Box, Button, Flex, MenuItem, Image, Menu, MenuButton, MenuList, Avatar, Badge, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useTheme } from "@chakra-ui/react";
import { useAuth } from "./AuthContext";
import { ChevronDownIcon } from "@chakra-ui/icons";
import UserProfile from "./UserProfile";
import whApiService from "./services/apiWhService";

function Header() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { currentUser } = useAuth(); // Use the useAuth hook
    const [subscriptionStatus, setSubscriptionStatus] = useState({ status: "inactive", validUntil: null });
    const [isLoading, setIsLoading] = useState(true); // New loading state

    useEffect(() => {
        const fetchSubscriptionData = async () => {
            if (currentUser) {
                const userId = currentUser.uid;
                var userData = await whApiService.getUserData(userId);

                if (userData) {
                    setSubscriptionStatus({ status: userData.subscriptionDetails.status, validUntil: userData.subscriptionDetails.validUntil });
                } else {
                    setSubscriptionStatus({ status: 'inactive', validUntil: null }); // Set default state if no data
                }
            }
        };

        fetchSubscriptionData();
    }, [currentUser]);


    const renderSubscriptionBadge = () => {
        if (subscriptionStatus === null) {
            return <Spinner size="sm" ml={2} />;
        } else if (subscriptionStatus.status === 'active') {
            return <Badge colorScheme="green" ml={2}>Valid until {subscriptionStatus.validUntil}</Badge>;
        } else {
            return <Badge colorScheme="red" ml={2}>No premium features</Badge>;
        }
    };

    return (
        <Flex bg={theme.colors.brand.background} p={4} color="white" alignItems="center">
            {/* Logo Image */}
            <Box onClick={() => navigate("/")} cursor="pointer">
                <Image src="/logo.png" alt="UT Web Helper Logo" boxSize="120px" />
            </Box>
            <Box ml="auto">
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/")}>
                    Home
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/features")}>
                    Features
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/howto")}>
                    How-To
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/about")}>
                    About
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/privacy")}>
                    Privacy Policy
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => (window.location.href = "https://chromewebstore.google.com/detail/ut-web-helper/afhliekobdpikegigkiihhoggiajijpf")}>
                    Download
                </Button>
                {/* <Button colorScheme="teal" variant="ghost" onClick={() => (window.location.href = "https://discord.gg/4Je9ymDg")}>
                    Discord
                </Button> */}

                <Menu>
                {currentUser && renderSubscriptionBadge()}
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        
                        
                        {currentUser ? <UserProfile /> : <Avatar size="md" />}
                    </MenuButton>

                    <MenuList>
                        {!currentUser ? (
                            <>
                                <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
                                <MenuItem onClick={() => navigate("/signup")}>Sign Up</MenuItem>
                            </>
                        ) : (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setSubscriptionStatus(null);
                                        auth.signOut();
                                        navigate("/");
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </Menu>
            </Box>
        </Flex>
    );
}

export default Header;
