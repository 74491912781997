import React from 'react';
import htmlString from './pp';
import { Box, Container } from '@chakra-ui/react';

const Policy = () => {

    return (
        <Container maxW="container.md" p={4}>
            <Box 
                bg="white" 
                p={5} 
                borderRadius="lg" 
                boxShadow="md" 
                dangerouslySetInnerHTML={{ __html: htmlString }}
            />
        </Container>
    );
};


export default Policy;
