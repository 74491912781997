import { Container, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";



function Features() {
   

    return (
        <Container maxW="container.md" mt={10}>
            <Heading as="h1" mb={6}>
                Features
            </Heading>
          
        </Container>
    );
}

export default Features;
