import { useEffect } from 'react';
import { getAuth, onIdTokenChanged } from 'firebase/auth';

const useTokenRefresh = () => {
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onIdTokenChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken();
                localStorage.setItem('token', token); // Update the token in local storage
            }
        });

        return () => unsubscribe();
    }, []);
};

export default useTokenRefresh;
