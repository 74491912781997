import axios from 'axios';

export const setupAxiosInterceptors = (onLogout) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 403) {
        onLogout(); // Call the logout handler
      }
      return Promise.reject(error);
    }
  );
};
