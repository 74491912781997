import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { onAuthStateChanged } from "firebase/auth";
import { loadStripe } from "@stripe/stripe-js";
import { auth } from "../firebase";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

function Checkout() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    const handlePurchase = async (type) => {
        const stripe = await stripePromise;
        const token = localStorage.getItem("token"); // Retrieve token from local storage

        // Call backend to create a new checkout session
        const response = await fetch(process.env.REACT_APP_API_SERVER + "/api/payment/create-checkout-session", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // Include token in headers
            },
            body: JSON.stringify({ userId: user.uid, type: type }), // Assuming you have the user's UID here
        });

        if (response.status === 409) {
            // Handle the case where the user already has a subscription
            alert("You've already paid for the subscription!");
            return;
        }

        const session = await response.json();

        // Redirect to Stripe's hosted checkout page
        const result = await stripe.redirectToCheckout({
            sessionId: session.sessionId,
        });

        if (result.error) {
            // Handle error here
        }
    };

    if (!user) {
        return <Alert variant="danger">You need to be logged in to view this content.</Alert>;
    }

    return (
        <Container maxW="container.md" mt={10}>
            <Heading as="h1" mb={6}>
                Premium Membership
            </Heading>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6}>
                    <Heading as="h3" size="lg">
                        1 Month Premium
                    </Heading>
                    <Text mt={2}>$1.99</Text>
                    <Button colorScheme="teal" mt={4} onClick={() => handlePurchase("month")}>
                        Get
                    </Button>
                </Box>
                <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6}>
                    <Heading as="h3" size="lg">
                        Season Pass
                    </Heading>
                    <Text mt={2}>$9.99</Text>
                    <Button colorScheme="teal" mt={4} onClick={() => handlePurchase("season")}>
                        Get
                    </Button>
                </Box>
            </SimpleGrid>
        </Container>
    );
}

export default Checkout;
