import React from "react";
import { Box, Container, Heading, Text, VStack, Divider, Icon, useColorModeValue, ListItem, ListIcon, List } from "@chakra-ui/react";
import { FaRocket, FaTools, FaUserAlt } from "react-icons/fa";
import { CheckIcon, CalendarIcon } from "@chakra-ui/icons";

const About = () => {
    // Variables to toggle visibility of sections
    const showWhyIDoIt = true;
    const showWhoIAm = true;
    const showFeatures = true;

    // Define color mode values outside of the return statement
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const textColor = useColorModeValue("gray.600", "gray.200");

    return (
        <Container maxW="container.xl" p={4}>
            <VStack spacing={10} alignItems="stretch">
                {/* UT Web Helper version and its features */}
                {showFeatures && (
                    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
                        <Heading as="h2" size="xl" mb={4}>
                            <Icon as={FaTools} w={6} h={6} mr={2} />
                            UT Web Helper Features
                        </Heading>

                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckIcon} color="green.500" />
                                Shortcuts - allows to quickly navigate throug pages and do actions assigned to keys
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckIcon} color="green.500" />
                                Auto relist - when you left your browser open with WebApp open then every 1 hour and 5 minutes your transfer list will be relisted and sold players
                                removed.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                                Sniper - A FUT Sniper. Search cards at your max price and buy it quickly as it is possible
                            </ListItem>
                            <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                                Mass Bid - If you would like to take more cards at the time.
                            </ListItem>
                            <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />   
                                SBC - Let's save the time with automaticly resolved SBC based on the solutions from most popular FUT website
                            </ListItem>
                            <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                                Prices - Show current prices taken form the most poplar FUT website
                            </ListItem>
                            <ListItem>
                            <ListIcon as={CheckIcon} color="green.500" />
                                Filters - Saved filters to quickly access your favourite searches
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CalendarIcon} color="gray.500" />
                                Messaging - Integration with Telegram
                            </ListItem>
                        </List>
                    </Box>
                )}

                {/* Why I am doing it? */}
                {showWhyIDoIt && (
                    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
                        <Heading as="h2" size="xl" mb={4}>
                            <Icon as={FaRocket} w={6} h={6} mr={2} />
                            Why I am doing it?
                        </Heading>
                        <Text fontSize="lg" color={textColor} margin={2}>
                            {/* Your content here */}I have been playing Ultimate Team since 2014 but I have never had a good results as the phrase says "practice makes perfect".
                            but I have never had enough time to play as much as I want to. Once you dont play too much then you cannot have coins to afford decent players for my
                            ocasionaly games. So as there was a feature of Web App/Companion App which allows to trade cards straight from your pocket it gave me a posibility to
                            get some coins.
                        </Text>
                        <Text fontSize="lg" color={textColor} margin={2}>
                            And again... to be better I need to spend more time in front of computer or smartphone. That's why I started playing with some automations that can help
                            me. As I started it, I quicky learn that is not allowed by game producers to make any automations. It allows me to learn to be patient and smart about
                            my market movements. As it is like a trading on stock market, automations are everwhere so I started to support myself in the way not to upset the game
                            owners as they not allow this but everyone is using some support from tools :)
                        </Text>
                    </Box>
                )}

                {/* Who am I? */}
                {showWhoIAm && (
                    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
                        <Heading as="h2" size="xl" mb={4}>
                            <Icon as={FaUserAlt} w={6} h={6} mr={2} />
                            Who am I?
                        </Heading>
                        <Text fontSize="lg" color={textColor}>
                            I am profesional software developer. I love to build software and this is my first product which I was building for many years. I would like to share it
                            with others an allow to help solve issues as I had during the game but trying to keep account safe.
                        </Text>
                        <Text fontSize="lg" color={textColor}>
                            I am focues on keeping software quality so if you see any places to improvement please contact me.
                        </Text>
                    </Box>
                )}
            </VStack>
        </Container>
    );
};

export default About;
