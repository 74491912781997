import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCccldfqkDcc8o-6kTYKtza7XtSAig9ERg",
  authDomain: "whwebhelper.firebaseapp.com",
  projectId: "whwebhelper",
  storageBucket: "whwebhelper.appspot.com",
  messagingSenderId: "933755673434",
  appId: "1:933755673434:web:c40eb84c2cfb7d08ce3163",
  measurementId: "G-NCE03SPSMJ"
};

const app = initializeApp(firebaseConfig);


const auth = getAuth(app);

export { auth };
