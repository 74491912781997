// This file contains your authentication related functions
export const clearAuthData = () => {
    localStorage.removeItem('token'); // Or however you're storing the token
    // Add other cleanup logic if necessary
  };
  
  export const logoutUser = () => {
    clearAuthData();
    window.location.href = '/login'; // Redirect to login page
  };
  