import React, { useState } from 'react';
import { useAuth } from './AuthContext'; // Adjust this import according to your project structure
import { sendEmailVerification } from 'firebase/auth';
import {
  Box,
  Text,
  Button,
  useToast,
  Flex
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const EmailVerification = () => {
  const { currentUser } = useAuth(); // Replace with your auth context or hook
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  if (currentUser.emailVerified) {
    navigate('/')
    return false;
  }
  const resendVerificationEmail = async () => {
    try {
      setIsSending(true);
      await sendEmailVerification(currentUser);
      toast({
        title: "Verification Email Sent",
        description: "Check your email for the verification link.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send verification email. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Flex
      align="center" // Vertically center
      justify="center" // Horizontally center
      height="50vh" // Full viewport height
    >
      <Box p={5} shadow="md">
        <Text mb={4}>Please verify your email address. Check your inbox for the verification email.</Text>
        <Flex justifyContent="center"> {/* Centering the Button */}
        <Button
          onClick={resendVerificationEmail}
          isLoading={isSending}
          colorScheme="teal"
          variant="outline"
        >
          Resend Verification Email
        </Button>
      </Flex>
      </Box>
    </Flex>
  );
};

export default EmailVerification;
