import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  Button
} from '@chakra-ui/react';

function SuccessPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');

  return (
    <Container maxW="container.md" mt={10} centerContent>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={6} textAlign="center">
        <Heading as="h1" size="xl" mb={6}>Payment Successful!</Heading>
        <Text fontSize="lg" mb={4}>
          Thank you for your payment. Your transaction ID is: {sessionId}
        </Text>
        <Button colorScheme="teal" mt={4} onClick={() => window.location.href = '/'}>
          Return to Home
        </Button>
      </Box>
    </Container>
  );
}

export default SuccessPage;
