import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    brand: {
      primary: "#4CAF50",
      secondary: "#1E88E5",
      neutral: "#2C3E50",
      accent: "#FFC107",
      background: "#F5F5F5",
      text: "#212121" // Dark text
    }
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
      },
      variants: {
        "primary": {
          bg: "brand.primary",
          color: "white",
          _hover: {
            bg: "brand.secondary",
          },
        },
        "secondary": {
          bg: "brand.secondary",
          color: "white",
          _hover: {
            bg: "brand.primary",
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: "bold",
        color: "brand.text",
      },
    },
    Text: {
      baseStyle: {
        color: "brand.text",
      },
    },
    Menu: {
      parts: ['list', 'item'],
      baseStyle: {
        item: {
          color: "brand.text", // Use the dark text color for menu items
          _hover: {
            bg: "brand.accent", // Optional: change background on hover
          },
          _focus: {
            bg: "brand.secondary", // Optional: change background when focused
          },
        },
      },
    },
  },
});
