import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";

const API_BASE_URL = process.env.REACT_APP_API_SERVER;

const whApiService = {
    /**
     * Fetch user data from the database.
     * @param {string} userId - The ID of the user to fetch.
     */
    getUserData: async (userId) => {
        try {
            const auth = getAuth();
            const token = await getIdToken(auth.currentUser);
            const response = await axios.get(`${API_BASE_URL}/api/user/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            return response.data; // Adjust based on your API's response structure
        } catch (error) {
            console.error("Error fetching user data:", error);
            throw error;
        }
    },

    saveUserData: async (userData) => {
        try {
            const auth = getAuth();

            const token = await getIdToken(auth.currentUser);

            const response = await axios.post(`${API_BASE_URL}/api/user`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data; // You can adjust this return based on your API's response structure
        } catch (error) {
            console.error('Error saving user data:', error);
            throw error; // Rethrow the error for the caller to handle
        }
    },

    // Add other API methods here as needed
};

export default whApiService;
